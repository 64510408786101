@import url(https://fonts.googleapis.com/css?family=EB+Garamond|Montserrat|Open+Sans&display=swap);
html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'EB Garamond', Georgia, 'Times New Roman', serif;
  font-weight: 600;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 33px;
}

h3 {
  font-size: 26px;
}

strong {
  font-weight: 900;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  transition: ease-in-out 0.5s;
}

a :hover {
  color: #000000;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 700ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.reactmodal_content {
  z-index: 2;
  width: 40%;
  height: 60%;
  margin: auto;
  display: flex;
  alignitems: stretch;
}

